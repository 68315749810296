<template>
    <div>
        <responses-by-survey :id="id" v-if="by == 'survey'"></responses-by-survey>
    </div>
</template>
<script>

import ResponsesBySurvey from "@c/responses/ResponsesBySurvey"

export default {
    name: "Responses", 
    props: {
        id: { type:String },
        by: { type: String, default: "survey"}
    },
    components: {
        ResponsesBySurvey
    }
}
</script>