<template>
	<div>
		<response-table
			v-if="survey"
			:value="responses"
			:questions="questions"
			:selected="selected"
			@select="(v) => (selected = v)"
		></response-table>
		<secondary-navigation show-back>
			<template v-slot:main>
				<edit-response-button v-if="selected.length == 1" :id="selected[0].id" show-export show-contacts show-date></edit-response-button>
				<delete-response-button v-if="selected.length > 0" :value="selected" @input="selected = []"></delete-response-button>
			</template>
		</secondary-navigation>
	</div>
</template>
<script>
import ResponseTable from "@c/responses/ResponseTable.vue";
import SecondaryNavigation from "@c/navigation/Secondary.vue";
import EditResponseButton from "@c/responses/EditResponseButton.vue";
import DeleteResponseButton from "@c/responses/DeleteResponseButton.vue";

export default {
	name: "ResponsesBySurvey",
	props: {
		id: { type: String },
		prefill: { type: Object },
		showEdit: { type: Boolean, default: true },
	},
	data: () => {
		return {
			selected: [],
			dialog: false,
		};
	},
	computed: {
		responses() {
			let id = this.id;
			return Object.values(this.$store.state.surveyResponses.data)
				.filter((a) => a.survey == id)
				.map((a) => a.id);
		},
		survey() {
			return this.$store.state.surveys.data[this.id];
		},
		questions() {
			const self = this;
			let questions = this.survey.questions;
			return questions.filter((q) => {
				let question = self.$store.state.questions.data[q];
				return question && question.type !== "html";
			});
		},
	},
	components: {
		ResponseTable,
		SecondaryNavigation,
		EditResponseButton,
		DeleteResponseButton
	},
	methods: {
		edit() {},
	},
	created() {
		this.$store.dispatch("surveyResponses/fetchBySurvey", this.id);
	},
};
</script>
